<!-- This component has no actual HTML (it's just a script), but because of how
    Svelte + our rollup config is set up, it's much easier to write it as a
    .svelte  file rather than a .js file.-->
<script lang="ts">
    import { onMount } from "svelte";

    import { snackbar } from "./stores.js";

    export let msg: string;

    onMount(() => {
        $snackbar.show(msg);
    });
</script>
