<script lang="ts">
    import Snackbar, { Actions, Label } from "@smui/snackbar";

    import { Button } from "@propublica/pp-svelte";
    import CloseIcon from "@propublica/pp-v5-shared/src/icons/close.svg";

    let snackbar: Snackbar;
    let snackbarText: string = "";

    export function show(textToShow: string) {
        // In case a previous alert is still visible
        snackbar.close();
        snackbarText = textToShow;
        snackbar.open();
    }
</script>

<!-- I verified in @smui/snackbar source code that labelText gets set in the
        DOM using `.textContent` so malicious content
        (e.g. <script>evil()</script>@gmail.com) should not be a risk
        (https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent#differences_from_innerhtml)
    -->
<Snackbar bind:this={snackbar} labelText={snackbarText} timeoutMs={10000}>
    <!-- "Label" is the the part of the snackbar where text goes. It is
            empty, becuase we are using `labelText` prop to fill it. -->
    <Label />
    <!-- "Actions" is just the part of the snackbar where buttons go.
            If we used more @smui components, we would get some magic behavior
            here (i.e. @smui/IconButton automatically performs close behavior
            on click), but instead we use our Button and add on:click. -->
    <Actions>
        <Button
            on:click={() => {
                snackbar.close();
            }}
            iconOnly={true}
            textColor="var(--color-light-full)"
            bgColor="transparent"
        >
            Close alert.
            <CloseIcon slot="iconBefore" />
        </Button>
    </Actions>
</Snackbar>
