<!-- Parent component is OrgSummaryCharts.svelte -->

<script lang="ts">
  /* Real imports */
  import { LayerCake, Svg } from "layercake";
  import Column from "./_components/Column.svelte";
  import ColumnException from "./_components/ColumnException.svelte";
  import AxisX from "./_components/AxisX.svelte";
  import AxisY from "./_components/AxisY.svelte";
  import { scaleBand, format } from "d3";
  
  /* Type imports */
  import type { HighlightData } from "./_components/Column.svelte";

  // The index of the y variable to be visualized year over year
  export let yIndex:number;
  // Chart header
  export let label:string;
  // Organization data passed from OrgSummaryCharts.svelte
  export let data:[number, number, number, number, number, string][];
  // xTicks is set from OrgSummaryCharts.svelte
  export let xTicks:number[];
  // An array of ranges of years where there are years with exceptions "missing" or "multiple" filings
  export let exceptions:number[][][];

  // Screen size affects chart styles
  let screenSize:number;
  $: isMobile = screenSize < 960; // Roughly equivalent to 36em / lg screen size
  
  // The index for the x variable is 0;
  const xIndex = 0;
  $: padding = { top: 0, right: 0, bottom: 20, left: isMobile ? 35 : 50 }

  // Set yDomain
  let yData = data.map((a) => +a[yIndex]);
  const yDomain = [Math.min(0, ...yData), Math.max(0, ...yData)]; // yDomain[0] must always be <= 0, yDomain[1] must always be >= 0
  let yValsAreZero:boolean = false;
  if (yDomain[0] == 0 && yDomain[1] == 0) {
    yDomain[1] = 700; // If the yDomain is [0, 0], replace yDomain[1] with a hardcoded value
    yValsAreZero = true;
  }
  $: yTicks = (yDomain[1] - yDomain[0]) < 4 ? 1 : isMobile ? 2 : 4   // If the size of the yDomain is small like $1 or -$3, then the tickCount should be 1 to avoid decimal ticks.

  // Format Y-axis with scientific notation. Replace G with B.
  const siFormat = format("$.3~s");
  const formatBigNumber = (num: number) => siFormat(num).replace("G", "B");

  /* Code for showing the big number */
  const lastXVal = data[data.length - 1][0];
  const lastYVal = yData[yData.length - 1];
  const highlightDivId = `chart-highlight-${yIndex}`;
  let highlight: HighlightData | null = null;
  $: highlightedXVal = highlight?.year ?? lastXVal;
  $: highlightedYVal = highlight?.yValue ?? lastYVal;
</script>

<svelte:window bind:innerWidth={screenSize} />

<!-- Chart header -->
<h4>
  <div class="property-label">{label}</div>  
  <div id={highlightDivId} class="curr-value" aria-live="polite">
    {highlightedYVal === null
      ? "Unknown"
      : formatBigNumber(highlightedYVal)}
    <span class="curr-year">({highlightedXVal})</span>
  </div>
</h4>

<div class="chart-container">
  <LayerCake
    padding={padding}
    x={xIndex}
    y={yIndex}
    yNice={yTicks}
    xScale={scaleBand().padding(0.1).round(true)}
    {data}
    {yDomain}
  >
    <Svg>
      <ColumnException {exceptions}/>
      <AxisX
        gridlines={false}
        baseline={true}
        tickMarks={true}
        ticks={xTicks}
        yTick={"1.35em"}
        />
      <AxisY
        formatTick={formatBigNumber}
        ticks={yTicks}
        showZeroTickOnly={yValsAreZero}
        textAnchor={"end"}
        xTick={padding.left - 5}
        dyTick={"0.35em"}
        />
      <Column {highlightDivId} bind:highlight /> 
    </Svg>
  </LayerCake>
</div>

<style lang="scss">
  @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";
  h4 {
    border-bottom: 1px solid var(--gray-10);
    padding: var(--spacing0);
    margin-bottom: var(--spacing0);

    @include breakpoint-max(sm) {
      padding: var(--spacing-1);
    }
    
    .property-label {
      font-size: var(--scale-1);
      font-weight: 500;
      color: var(--text-gray-mid);
    }
    .curr-value {
      font-size: var(--scale1);
      font-weight: 700;
      color: var(--text-gray-dark);
      margin-top: var(--spacing-2); // Spacing between propety label and curr-value
    }

    .curr-year {
      font-size: var(--scale-2);
      font-weight: 400;
      color: var(--gray-60);
    }
  }

  .chart-container {
    padding: var(--spacing0);
    margin-top: var(--spacing-2);
    width: 100%;
    height: 160px;
    
    @include breakpoint-max(lg) {
      height: 120px;
    }

    @include breakpoint-max(sm) {
      height: 100px;
      padding: var(--spacing-1);
    }
  }
</style>
