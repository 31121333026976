<script lang="ts" context="module">
   export function getExceptionRanges(data:[number, number, number, number, number, string][], exceptionType:("missing" | "multiple")): Array<number>[] {
        // Create raw array with just the years that match the exception
        let exceptionArrRaw:number[] = []
        for (let row of data) {
            let year = row[0];
            let status:string = row[5];
            if (status == exceptionType) exceptionArrRaw.push(year)
        }

        let exceptionArrPairs:number[][] = [];
        let currPair:number[] = [];
        // Iterate through the raw array and create start and end year pairs where there were exceptions
        for (let i = 0; i <= exceptionArrRaw.length - 1; i++) {
            let currYear = exceptionArrRaw[i]
            if (currPair.length == 0) {
                // currPair is empty, currYear will be the first value
                currPair.push(currYear)
            } else if (currPair.length == 1) {
                if (currYear == currPair[0] + 1) {
                // currPair has first value, if currYear is the successive year of currPair[0], currYear will be the second value
                currPair.push(currYear)
                } else {
                // otherwise, push currPair to exceptionArrPairs and start a new currPair with currYear as the first value
                exceptionArrPairs.push(currPair)
                currPair = [currYear]
                }
            } else if (currPair.length == 2) {
                if (currYear == currPair[1] + 1) {
                // currPair has two values, if currYear is the successive year of currPair[1], override currPair[1] with currYear
                currPair[1] = currYear
                } else {
                // otherwise, push currPair to exceptionArrPairs and start a new currPair with currYear as the first value
                exceptionArrPairs.push(currPair)
                currPair = [currYear]
                }
            }
            if (i == exceptionArrRaw.length - 1) {
                // if this is the last value in the raw array, push currPair to exceptionArrPairs
                exceptionArrPairs.push(currPair)
            }
        }
        return exceptionArrPairs;
    }
</script>

<script lang="ts">
    import OrgSummaryChart from "./OrgSummaryChart.svelte";

    export let data:[number, number, number, number, number, string][];

    // The propertiesInfo key is the property's index in the array of data
    type propertyInfo = {[key: string]: { label: string; }};
    const propertiesInfo:propertyInfo = {
        1: {label: "Revenue"},
        2: {label: "Expenses"},
        3: {label: "Total Assets"},
        4: {label: "Total Liabilities"},
    };

    // If there is only one year of data, the xDomain should be just one value.
    let xData = data.map((a) => a[0]);
    let xTicks = xData.length == 1 ? [Math.max(...xData)] : [Math.min(...xData), Math.max(...xData)];

    // Get arrays of years when there are missingFilings and multipleFilings
    // Example: If years with exceptions are 2012, 2013, 2014, 2018, 2020, 2021 the output would be [[2012,2014], [2018], [2020,2021]]
   
    let exceptionArrPairs_missing = getExceptionRanges(data, "missing");
    let exceptionArrPairs_multiple = getExceptionRanges(data, "multiple");
    let exceptions:number[][][] = [exceptionArrPairs_missing, exceptionArrPairs_multiple]
</script>

<figure class="org-top__right">
    <h3 class="a11y">Summary charts: organization finances over time</h3>
    <svg style="position: absolute; height: 0; width: 0;">
        <!-- Stripe patterns for missing and multiple filing exceptions -->
        <defs>
            <pattern id="pattern_missing" patternUnits="userSpaceOnUse" width="6.5" height="6.5" patternTransform="rotate(45)">
              <line x1="0" y="0" x2="0" y2="6.5" stroke="var(--gray-10)" stroke-width="3" />
            </pattern>
            <pattern id="pattern_multiple" patternUnits="userSpaceOnUse" width="6.5" height="6.5" patternTransform="rotate(-45)">
              <line x1="0" y="0" x2="0" y2="6.5" stroke="var(--color-accent-20)" stroke-width="3" />
            </pattern>
          </defs>
    </svg>

    <!-- Charts -->
    <div class="org-summary-charts-wrapper stack stack--row stack--spacing-none stack--wrap">
        {#each Object.keys(propertiesInfo) as yIndex}
            <div class="org-summary-chart">
                <OrgSummaryChart {data} {xTicks} yIndex={+yIndex} label={propertiesInfo[yIndex].label} {exceptions}/>
            </div>
        {/each}
    </div>

    <!-- Legend -->
    {#if exceptionArrPairs_missing.length >= 1 || exceptionArrPairs_multiple.length >= 1}
        <div class="legend-wrapper attribution__source">
            {#if exceptionArrPairs_missing.length >= 1}
                <div class="legend">
                    <svg><rect x="0" y="0" fill="url(#pattern_missing)"/></svg>
                    Years with missing data
                </div>
            {/if}
            {#if exceptionArrPairs_multiple.length >= 1}
                <div class="legend">
                    <svg><rect x="0" y="0" fill="url(#pattern_multiple)"/></svg>
                    Years with multiple filings
                </div>
            {/if}
        </div>
    {/if}
    
    <figcaption class="attribution">
        <span class="attribution__source">
            Source: Form 990 tax filings from {xTicks[0]}
            {#if xTicks.length == 2} 
                to {xTicks[1]}
            {/if}
        </span>
    </figcaption>
    
</figure>

<style lang="scss">
    @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";
    
    .legend-wrapper {
        display: flex;
        margin-top: var(--spacing-1);

        .legend {
            display: flex;
            align-items: center;

            svg {
                display: inline-block;
                width: 16px !important;
                height: 16px !important;
                border: 1px solid var(--gray-20);
                margin-right: var(--spacing-2);
            }
            rect {
                width: 20px;
                height: 20px;
            }
        }
    }

  .org-summary-charts-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: var(--spacing0);
    
    @include breakpoint-max(sm) {
      grid-gap: var(--spacing-2);
    }

    .org-summary-chart {
        border: var(--gray-10) 1px solid;
        border-radius: 3px;
        background-color: white;
    }
  }

  figure {
    margin-bottom: var(--spacing2);
    background: #F9FAFA;
  }

</style>
