import { readable } from "svelte/store";

import NpeSnackbar from "./NpeSnackbar.svelte";

// If initializing snackbar fails, `snackbar.show()` becomes no-op (rather than
// throwing exception)
const dummySnackbar = { show: () => {} };

// References:
// - https://learn.svelte.dev/tutorial/writable-stores
// - https://svelte.dev/docs/svelte-components#script-4-prefix-stores-with-$-to-access-their-values
// - https://svelte.dev/docs/svelte-store#readable
export const snackbar = readable(
    /**@type {NpeSnackbar | typeof dummySnackbar} */ (dummySnackbar),
    (set) => {
        // Hack to avoid exception during SSR (apparently SSRing a component
        // that contains $snackbar tries to subscribe during SSR)
        if (typeof document === "undefined") return;

        const target = document.querySelector(".snackbar-target");
        if (target === null) {
            console.error("Cannot initialize snackbar");
            return;
        }
        const npeSnackbar = new NpeSnackbar({ target });
        set(npeSnackbar);
        return () => {
            npeSnackbar.$destroy();
        };
    }
);

export const csrfTokenPromise = readable(
    /** @type { Promise<string|null> } */ (Promise.resolve(null)),
    (set) => {
        // Avoid running during SSR (see comment above)
        if (typeof document === "undefined") return;

        /** @type {string} */
        let csrfTokenEndpoint;
        try {
            // Could be null, but then exception in next line will be caught
            const jsonElem = /** @type {HTMLScriptElement} */ (
                document.querySelector("script.csrf-token-endpoint")
            );
            const jsonStr = /** @type {string} */ (jsonElem.textContent);
            csrfTokenEndpoint = JSON.parse(jsonStr);
        } catch {
            console.error("Cannot load CSRF token endpoint");
            return;
        }

        fetch(csrfTokenEndpoint).then((res) => {
            if (res.ok) set(res.json());
        });
    }
);
