<script context="module" lang="ts">
    // This should describe the shape of successful JSON output from
    // subscriptions#index route
    type ListSubscriptionsResult = {
        emailAddress: string;
        eins: number[];
    };

    let listResPromise: Promise<Response> | null = null;
    let listDataPromise: Promise<ListSubscriptionsResult> | null = null;

    let maybeSendWelcome = true;
</script>

<script lang="ts">
    import { onMount, tick } from "svelte";

    import { Button } from "@propublica/pp-svelte";

    import FollowModal from "./FollowModal.svelte";
    import { csrfTokenPromise, snackbar } from "./stores.js";
    import { EmailFormState } from "./VerifyEmail.svelte";

    /* Props */

    export let ein: number;
    export let orgName: string;
    export let showExplanatoryText: boolean = false;
    export let followEndpoint: string;
    export let listEndpoint: string;
    export let unfollowEndpoint: string;
    export let verifyEndpoint: string;

    /* Config / constants */
    // TODO(@alecglassford): Replace "..." with @tealtan's SVG spinner
    const disabledText: Map<EmailFormState, string> = new Map([
        [EmailFormState.Sent, "Confirmation email sent"],
        [EmailFormState.RateLimit, "Try again later"],
    ]);

    /* Component state */

    let buttonWrapperElem: HTMLDivElement;
    let loggedInEmailAddress: string | null = null;
    let waitingForSubscriptions = true;
    let clickBeingHandled = false;
    let modalIsVisible = false;
    let formState: EmailFormState = EmailFormState.Fresh;
    let loggedIn = false;
    let following = false;
    $: buttonIsDisabled =
        waitingForSubscriptions ||
        clickBeingHandled ||
        modalIsVisible ||
        formState !== EmailFormState.Fresh;

    /* onMount */

    async function loadSubscriptions(): Promise<void> {
        if (listResPromise === null) listResPromise = fetch(listEndpoint);
        const res = await listResPromise;
        if (res.ok) {
            if (listDataPromise === null) listDataPromise = res.json();
            const resBody = await listDataPromise;
            following = resBody.eins.includes(ein);
            loggedInEmailAddress = resBody.emailAddress;
            loggedIn = true;
            waitingForSubscriptions = false;
        } else if (res.status === 403) {
            // 403 means not logged in (loggedIn and following stay false)
            waitingForSubscriptions = false;
        } else {
            // Any other status is unexpected. (button remains disabled)
            console.error(
                "Failed to load subsciption list:",
                res.status,
                res.statusText
            );
        }
    }

    function alertEmailVerified(): void {
        const queryParams = new URLSearchParams(window.location.search);
        const verificationStatus = queryParams.get("verification");
        switch (verificationStatus) {
            case "verified-subscribed":
                $snackbar.show(`Your email address has been verified. You will
                    receive alerts for this organization.`);
                break;
            case "verified-only":
                $snackbar.show(`Your email address has been verified. However, you
                    have not been subscribed to alerts for this organization due
                    to an error. (It’s possible you were already subscribed.)`);
                break;
        }
    }

    onMount(() => {
        loadSubscriptions();
        alertEmailVerified();
    });

    /* Event handlers */

    async function follow(): Promise<void> {
        const csrfToken = await $csrfTokenPromise;
        if (csrfToken === null) {
            $snackbar.show("Authentication error.");
            return;
        }
        const res = await fetch(followEndpoint, {
            method: "POST",
            headers: {
                // what we want response to be (w/o this, Rails sends html)
                Accept: "application/json",
                // description of request body
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ ein, maybeSendWelcome }),
        });
        if (res.ok) {
            following = true;
            $snackbar.show(`You will receive alerts for ${orgName} at
                ${loggedInEmailAddress}.`);
        } else {
            $snackbar.show(`An error occurred while trying to subscribe to this
                organization. Please try again later.`);
        }
    }
    async function unfollow(): Promise<void> {
        const csrfToken = await $csrfTokenPromise;
        if (csrfToken === null) {
            $snackbar.show("Authentication error.");
            return;
        }
        const res = await fetch(unfollowEndpoint, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "X-CSRF-Token": csrfToken,
            },
        });
        if (res.ok) {
            following = false;
            $snackbar.show(`You will no longer receive alerts for
                ${orgName} at ${loggedInEmailAddress}.`);
        } else {
            $snackbar.show(`An error occurred while trying to unsubscribe from
                this organization. Please try again later.`);
        }
    }
    async function handleButtonClick(): Promise<void> {
        // Avoid user sending multiple requests in quick succession
        clickBeingHandled = true;
        if (following) {
            await unfollow();
        } else if (loggedIn) {
            await follow();
        } else {
            modalIsVisible = true;
        }
        clickBeingHandled = false;
        // After first click is handled (i.e. follow/unfollow behavior has
        // already happened on page), we disqualify button from triggering the
        // welcome email on any subsequent clicks (to avoid sending many emails
        // if user toggles sub/unsub back and forth)
        maybeSendWelcome = false;
    }
    async function focusButton(): Promise<void> {
        // https://svelte.dev/docs/svelte#tick
        // Need to await tick, because this event handler actually runs *before*
        // the button elements gets remounted. Wait until it exists, and then
        // query it.
        await tick();
        const buttonElem = buttonWrapperElem.querySelector(
            "button[data-follow-button-elem]"
        ) as HTMLButtonElement | null;
        if (buttonElem) buttonElem.focus();
    }
</script>

<!-- Parent div is needed due to
    https://github.com/propublica/pp-svelte/issues/80 🙃 -->
<div class="follow-btn" bind:this={buttonWrapperElem}>
    {#if formState !== EmailFormState.Fresh}
        <div class="disabled-text">{disabledText.get(formState)}</div>
    {:else}
        <Button
            shape="pill"
            buttonSize="var(--scale4)"
            miscAttrs={{
                "data-follow-button-elem": "",
                disabled: buttonIsDisabled,
            }}
            bgColor={following ? "var(--gray-10)" : "var(--color-accent-70)"}
            textColor={following ? "var(--color-text-body)" : "var(--white)"}
            on:click={handleButtonClick}
        >
            <!-- If modalIsVisible, keep showing "Subscribe", not spinner -->
            {#if buttonIsDisabled && !modalIsVisible}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-loader-2"
                    ><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg
                >
            {:else if following}
                <div
                    class="
                        stack
                        stack--row
                        stack--spacing-2
                        stack--align-center
                    "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-check"
                        ><path d="M20 6 9 17l-5-5" /></svg
                    ><span>Subscribed</span>
                </div>
            {:else}
                Subscribe
            {/if}
        </Button>
    {/if}
    <FollowModal
        {ein}
        {orgName}
        {verifyEndpoint}
        bind:modalIsVisible
        bind:formState
        on:modalclose={focusButton}
    />
</div>
{#if showExplanatoryText}
    <div class="text-sub follow-info">
        {#if following}
            You will receive
        {:else}
            Receive
        {/if}
        an email when new data is available for this organization.
    </div>
{/if}

<style lang="scss">
    .follow-btn {
        // Hardcoded numbers to roughly match the "biggest" state of this button
        // in order to avoid layout shifting when switching between states
        // This is pretty brittle and the numbers likely have to change if we
        // ever change the text, which is less than ideal, but it works for now.
        min-width: 8.25rem;
        min-height: 2.3rem;

        flex-shrink: 0; // do not shrink button

        svg {
        // make sure this matches what's set on the svgs
        height: 24px;
        width: 24px;
        }
    }

    :global(.follow-btn button) {
        // button should fill `.follow-btn` parent
        width: 100%;
        font-weight: 600 !important;
    }

    .follow-info {
        width: 250px;
        line-height: var(--line-height-1);
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .lucide-loader-2 {
        animation: spin 1s linear infinite;
    }

    .disabled-text {
        color: var(--gray-50);
        font-style: italic;
        font-size: var(--scale-2);
        text-align: center;
    }
</style>
