<!--
  @component
  Generates SVG rectangles where there are exceptions to the data. Companion to Column.svelte
 -->
<script>
  import { getContext } from 'svelte';

  const { xGet, xScale, height } = getContext('LayerCake');

  /** @type {String} [stroke='#000'] - The shape's stroke color. */
  export let stroke = '#000';

  /** @type {Number} [strokeWidth=0] - The shape's stroke width. */
  export let strokeWidth = 0;

  /** @type {Number[][][]} - An array of ranges of years where there are years with exceptions "missing" or "multiple" filings*/
  export let exceptions;
  
/** @type {function(any):number} */
  $: columnWidth = d => {
    const vals = $xGet(d);
    return Math.abs(vals[1] - vals[0]);
  };

</script>

  <g class="column-group">
  {#each exceptions as exceptionArrPairs, j}  
    {#each exceptionArrPairs as d, i}
      {@const colWidth = $xScale.bandwidth ? $xScale.bandwidth() : columnWidth(d)}
      <!-- Column multiplier of colWidth. [2012, 2014] would be 3 -->
      {@const colNum = d.length == 2 ? d[1] - d[0] + 1 : 1}
      <!-- Padding multiplier -->
      {@const paddingNum = colNum == 1 ? 0 : colNum - 1}
      
      <rect
        class='{j == 0 ? "rect-missing" : "rect-multiple"}'
        x="{$xGet(d)}"
        y="-2em"
        width="{colWidth * colNum + $xScale.padding() * colWidth * paddingNum}"
        height="calc({$height}px + 4.25em)"
        {stroke}
        stroke-width="{strokeWidth}"
      />
    {/each}
  {/each}
</g>

<style>
  .rect-missing {
    fill: url(#pattern_missing);
  }
  
  .rect-multiple {
    fill: url(#pattern_multiple);
  }
</style>