<script lang="ts">
    import { Button } from "@propublica/pp-svelte";
    import CloseIcon from "@propublica/pp-v5-shared/src/icons/close.svg";
    import SearchIcon from "@propublica/pp-v5-shared/src/icons/search.svg";

    /* Props */

    export let orgEndpoint: string;
    export let peopleEndpoint: string;
    export let fullTextEndpoint: string;

    /* Config / constants */

    // Set an explicit height so button and input heights match; otherwise there
    // can be a slight shift in subnav height when switching from "hidden" to
    // "shown" search on mobile.
    const COMPONENT_HEIGHT = "var(--scale4)";
    // Should be less than SUBNAV_HEIGHT
    const ICON_SIZE = "var(--scale1)";

    type SearchType = "org" | "people" | "fullText";
    type SearchOptions = {
        dropdownLabel: string;
        placeholder: string;
        endpoint: string;
    };
    const searchTypes: Readonly<Record<SearchType, Readonly<SearchOptions>>> = {
        org: {
            dropdownLabel: "Nonprofits",
            placeholder: "Search for a nonprofit",
            endpoint: orgEndpoint,
        },
        people: {
            dropdownLabel: "People",
            placeholder: "Search employees",
            endpoint: peopleEndpoint
        },
        fullText: {
            dropdownLabel: "Filing Text",
            placeholder: "Search full text of filings",
            endpoint: fullTextEndpoint,
        },
    };

    /* Stuff that actually changes */

    let searchVisibleOnMobile = false;
    let currentSearchType: SearchType = "org";
    $: currentSearchOpts = searchTypes[currentSearchType];
</script>

<div class="hide-above-md stack">
    <Button
        buttonSize={COMPONENT_HEIGHT}
        iconOnly={true}
        iconSize={ICON_SIZE}
        bgColor="transparent"
        on:click={() => {
            searchVisibleOnMobile = !searchVisibleOnMobile;
        }}
    >
        {#if searchVisibleOnMobile}
            Hide search box
        {:else}
            Show search box
        {/if}
        <svelte:fragment slot="iconBefore">
            {#if searchVisibleOnMobile}
                <CloseIcon />
            {:else}
                <SearchIcon />
            {/if}
        </svelte:fragment>
    </Button>
</div>
<!-- Provides full-viewport-width dark background for search "drawer" -->
<div
    class="mobile-backdrop hide-above-md"
    class:hide-below-md={!searchVisibleOnMobile}
/>
<form
    class="form stack stack--row stack--spacing-none subnav-search-form"
    class:hide-below-md={!searchVisibleOnMobile}
    action={currentSearchOpts.endpoint}
>
    <!-- Each input wrapper has "stack" to force its child to stretch to
        full height (similar to `height: 100%` on child)-->
    <div class="form__input-wrapper stack">
        <label for="subnav-search-type" class="a11y form__label"
            >Select the type of search</label
        >
        <select
            class="form__input-dropdown"
            id="subnav-search-type"
            style:width="calc({currentSearchOpts.dropdownLabel.length - 1} * 0.4em +
            var(--spacing1) * 2)"
            bind:value={currentSearchType}
        >
            {#each Object.entries(searchTypes) as [searchType, { dropdownLabel }]}
                <option value={searchType}>{dropdownLabel}</option>
            {/each}
        </select>
    </div>
    <div class="form__input-wrapper stack__item-grow stack">
        <label for="subnav-search" class="a11y form__label"
            >{currentSearchOpts.placeholder}</label
        >
        <input
            class="form__input-text"
            style:--component-height={COMPONENT_HEIGHT}
            id="subnav-search"
            name="q"
            placeholder={currentSearchOpts.placeholder}
        />
    </div>
    <div class="form__submit-btn-wrapper stack">
        <!-- Default click behavior of button in fomr is to submit form-->
        <Button
            buttonSize={COMPONENT_HEIGHT}
            iconOnly={true}
            iconSize={ICON_SIZE}
            bgColor="var(--color-accent-70)"
            shape="rect"
        >
            Search
            <SearchIcon slot="iconBefore" />
        </Button>
    </div>
</form>

<style lang="scss">
    @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";

    .mobile-backdrop {
        // position should use .subnav__inner as containing block
        position: absolute;
        // From top of .subnav__inner: shift down 100% height of that div +
        // padding-bottom of that div
        top: calc(100% + var(--subnav-padding-bottom));
        // Align with left of .subnav__inner, then …
        left: 0;
        // … shift right to align with middle of `.subnav__inner`, then shift
        // left to align with left edge of viewport.
        margin-left: calc(50% - 50vw);
        width: 100vw;
        // Set same "padding-bottom" in drawer as subnav itself
        height: calc(100% + var(--subnav-padding-bottom) * 2);
        background-color: var(--site-header-color-bg);
        
        box-shadow:  0px 2px 5px rgba(48, 48, 48, 0.3);
    }

    form {
        // `overflow: hidden` lets us set border-radius on whole form rather
        // than select, button individually 🤯
        overflow: hidden;
        border-radius: 3px;

        // Remove default border that pp-v5-shared sets on select, input
        // https://guides.propublica.org/design/storybook/?path=/docs/pp-v5-shared_forms-forms--page
        --form-border: none;

        @include breakpoint-max(md) {
            // position should use .subnav__inner as containing block
            position: absolute;
            // From top of .subnav__inner: shift down 100% height of that div +
            // padding-bottom of that div
            top: calc(100% + var(--subnav-padding-bottom));
            // Align right edge with .subnav__inner's right edge
            right: 0;
            // Align left edge with .subnav__inner's left edge
            left: 0;
        }
    }

    // `:global` to style the <button> inside <Button>
    select,
    input,
    :global(.subnav-search-form button) {
        // Due to overflow:hidden on form, default outline on focus (useful for
        // a11y; CSS is set by browser user-agent stylesheet) gets slightly cut
        // off. This moves the outline so it is entirely within each element
        // when you focus on it.
        outline-offset: -3px;
    }

    select {
        border-right: 1px solid var(--gray-20);
        background-color: var(--gray-10);
        padding-right: var(--spacing-1);
        transition: width 0.4s;
        font-size: var(--scale-2);
        font-weight: 600;
    }

    input {
        max-height: var(--component-height);
        font-size: var(--scale-2);

        @include breakpoint-min(md) {
            max-width: 10rem;
        }
        
        @include breakpoint-max(sm) {
            font-size: var(--scale0);
        }

        &::placeholder {
            font-size: var(--scale-2);
        }
    }
</style>
