<script lang="ts">
    import { Button } from "@propublica/pp-svelte";
    import SearchIcon from "@propublica/pp-v5-shared/src/icons/search.svg";
    import { slide } from "svelte/transition"

    /* Props */

    export let orgEndpoint: string;
    export let peopleEndpoint: string;
    export let fullTextEndpoint: string;
    type state = { abbr: string; full: string };
    export let statesList: state[] = [];

    type SearchType = "org" | "people" | "fullText";
    export let initialSearchType: SearchType = "org";

    export let initialInputValue = "";

    type example = { href: string; text: string };
    // You must either specify sets of examples for ALL searches or for NONE.
    // Due to https://github.com/propublica/pp-svelte/issues/80, if you only
    // provide examples for some, you might see examples that disappear when
    // you change the dropdown but then will never re-appear if you change it
    // back. (Lucklily, this is not a use case for us right now.)
    export let orgExamples: example[] = [];
    export let peopleExamples: example[] = [];
    export let fullTextExamples: example[] = [];

    /* Config / constants */

    const ICON_SIZE = "var(--scale1)";

    type SearchOptions = {
        dropdownLabel: string;
        placeholder: string;
        endpoint: string;
        examples: example[];
    };
    const searchTypes: Readonly<Record<SearchType, Readonly<SearchOptions>>> = {
        org: {
            dropdownLabel: "Nonprofits",
            placeholder: "Search for a nonprofit, keyword, city or EIN",
            endpoint: orgEndpoint,
            examples: orgExamples,
        },
        people: {
            dropdownLabel: "People",
            placeholder: "Search by name or position",
            endpoint: peopleEndpoint,
            examples: peopleExamples,
        },
        fullText: {
            dropdownLabel: "Filing Text",
            placeholder: "Search full text of filings",
            endpoint: fullTextEndpoint,
            examples: fullTextExamples,
        },
    };

    /* Pure function */
    function postExampleConjunction(index: number, numExamples: number) {
        if (index < numExamples - 2) return ", ";
        if (index === numExamples - 2) return " or ";
        return ""; // i.e. last example
    }

    /* Stuff that actually changes */

    let currentSearchType: SearchType = initialSearchType;
    $: currentSearchOpts = searchTypes[currentSearchType];
    $: showAdvanced = false;
</script>

<label for="big-search" class="mobile-label form__label hide-above-sm">
    {currentSearchOpts.placeholder}
</label>
<form action={currentSearchOpts.endpoint}>
  <div class="form stack stack--row stack--spacing-none">
    <!-- Each input wrapper has "stack" to force its child to stretch to
        full height (similar to `height: 100%` on child)-->
    <div class="form__input-wrapper stack">
        <label for="big-search-type" class="a11y form__label"
            >Select the type of search</label
        >
        <!-- var(--spacing1) is the element padding. 6.5px is the average letter size on screen sizes sm and below -->
        <select
            class="form__input-dropdown"
            id="big-search-type"
            style:width="calc({currentSearchOpts.dropdownLabel.length - 1} * 6.5px +
            var(--spacing1) * 2)"
            bind:value={currentSearchType}
        >
            {#each Object.entries(searchTypes) as [searchType, { dropdownLabel }]}
                <option
                    value={searchType}
                    selected={currentSearchType === searchType}
                    >{dropdownLabel}</option
                >
            {/each}
        </select>
    </div>
    <div class="form__input-wrapper stack__item-grow stack">
        <label for="big-search" class="a11y form__label hide-below-sm"
            >{currentSearchOpts.placeholder}</label
        >
        <input
            class="form__input-text"
            id="big-search"
            name="q"
            placeholder={currentSearchOpts.placeholder}
            value={initialInputValue}
        />
    </div>
    <div class="form__submit-btn-wrapper stack">
        <!-- Default click behavior of button in fomr is to submit form-->
        <Button
            iconOnly={true}
            iconSize={ICON_SIZE}
            buttonSize="100%"
            bgColor="var(--color-accent-70)"
            shape="rect"
        >
            Search
            <SearchIcon slot="iconBefore" />
        </Button>
    </div>
  </div>

  <div class="examples-advanced-area">
    {#if currentSearchOpts.examples.length > 0}
      <div class="examples">
        Examples:
        {#each currentSearchOpts.examples as example, i}
          <a href={example.href}>{example.text}</a>{postExampleConjunction(
          i,
          currentSearchOpts.examples.length
          )}
        {/each}
      </div>
      <div class="advanced" style="cursor: pointer;">
        <span on:keypress={()=>{}} on:click={() => { showAdvanced=!showAdvanced }}>Advanced</span>
      </div>
    {/if}
  </div>

  {#if showAdvanced}
    <div id="advanced-dropdown" transition:slide>
      <div class="filters-section">
        <label for="state-filter">
          Filter by State
        </label>
        <select id="state-filter" name="state[]">
          <option value=''>- Select a state -</option>
          {#each statesList as state}
            {#if state.abbr != "ZZ" }
              <option value='{state.abbr}'>{state.name}</option>
            {/if}
          {/each}
        </select>
      </div>

    <table>
      <caption>
        <div class="section-title">Advanced Search Operators</div>
        All searches accept advanced syntax that lets you target more specific
        results. These are the available search operators and some examples on
        how to use them.
      </caption>
      <thead>
        <tr>
          <th>Description</th>
          <th>Operator</th>
          <th>Example Query</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Search for an exact word or phrase</td>
          <td><code>"</code></td>
          <td><a href={`${fullTextEndpoint}?q=\"first+class\"`}>"first class"</a></td>
        </tr>
        <tr>
          <td>Return results that include both phrases</td>
          <td><code>+</code></td>
          <td><a href={`${orgEndpoint}?q=church+%2B+state`}>church + state</a></td>
        </tr>
        <tr>
          <td>Return results that include one phrase or another</td>
          <td><code>|</code></td>
          <td><a href={`${orgEndpoint}?q=GOP+%7C+Republican`}>GOP | Republican</a></td>
        </tr>
        <tr>
          <td>Return results that do <em>not</em> include a word</td>
          <td><code>-</code></td>
          <td><a href={`${orgEndpoint}?q=football+-soccer`}>football -soccer</a></td>
        </tr>
        <tr>
          <td>At the end of a word, signifies wildcard/fuzzy</td>
          <td><code>*</code></td>
          <td><a href={`${orgEndpoint}?q=joset*`}>Joset*</a> (matches Josette, Josetta)</td>
        </tr>
        <tr>
          <td>In queries that combine different operators, signifies precedence.</td>
          <td><code>(</code> and <code>)</code></td>
          <td><a href={`${peopleEndpoint}?q=%28Homer+%7C+Marge%29+%2B+president`}>(Homer | Marge) + president</a></td>
        </tr>
      </tbody>
    </table>
  </div>

{/if}
</form>

<style lang="scss">
    @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";

    .examples-advanced-area {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    #advanced-dropdown {
      padding: 0;
      margin-top: var(--spacing-1);
      color: var(--color-text-body);
      font-family: var(--fonts-sans);
      line-height: var(--line-height-1);
      font-size: var(--scale-2);

      .filters-section {
        padding-bottom: var(--spacing1);

        label {
          display: block;
          font-weight: 700;
          margin-bottom: var(--spacing-2);
        }

        #state-filter {
          display: block;

          font-size: var(--scale-2);
          font-weight: normal;

          border-radius: 4px;
          border: 1px solid var(--gray-20);
          padding: var(--scale-2);
          background-color: white;
          width: 100%;
        }
      }

      table {
        border: 1px solid var(--gray-10);

        .section-title {
          display: block;
          font-weight: 700;
          margin-bottom: var(--spacing-2);
        }

        caption {
          text-align: left;
          margin-bottom: var(--spacing-1);
        }

        tbody tr:nth-child(odd) {
          background-color: #F5F5F5;
        }

        a {
          text-decoration: none;
          color: var(--blue-70);
          font-weight: 500;
        }
      }
    }

    .mobile-label {
        font-size: var(--scale-1);
        font-weight: 400;
        margin-bottom: var(--spacing-2);
        color: var(--gray-50); // mimic :placeholder
    }

    .form {
        margin-bottom: var(--spacing-1);

        // `overflow: hidden` lets us set border-radius on whole form rather
        // than select, button individually 🤯
        overflow: hidden;
        border-radius: 3px;
        border: 1px solid var(--gray-20);

        // Use a darker border color on homepage for emphasis
        :global(.home-top) & {
            border-color: var(--gray-40);
        }

        height: var(--scale6);
        max-width: 38rem;

        // Remove default border that pp-v5-shared sets on select, input
        // https://guides.propublica.org/design/storybook/?path=/docs/pp-v5-shared_forms-forms--page
        --form-border: none;
    }

    // `:global` to style the <button> inside <Button>
    select,
    input,
    :global(button) {
        // Due to overflow:hidden on form, default outline on focus (useful for
        // a11y; CSS is set by browser user-agent stylesheet) gets slightly cut
        // off. This moves the outline so it is entirely within each element
        // when you focus on it.
        outline-offset: -3px;
    }

    select {
        border-right: 1px solid var(--gray-20);
        // Use a darker border color on homepage for emphasis
        :global(.home-top) & {
            border-color: var(--gray-40);
        }
        background-color: var(--gray-10);
        font-weight: 600;
        transition: width 0.4s;

        @include breakpoint-max(sm) {
            padding-right: var(--spacing1);
        }

        @include breakpoint-min(sm) {
            width: auto !important;
        }
    }

    input {
        @include breakpoint-max(sm) {
            font-size: var(--scale0);
        }

        &::placeholder {
            color: var(--gray-50);

            @include breakpoint-max(sm) {
                // Hide placeholder by matching background
                color: var(--white);
            }
        }
    }

    .advanced,
    .examples {
        font-size: var(--scale-2);
        color: var(--color-text-meta);
    }
</style>
