<script lang="ts" context="module">
  export type HighlightData = {year: number, yValue: number};
</script>

<!--
  @component
  Generates an SVG column chart.
  Derived from https://layercake.graphics/example/Column
 -->
<script lang="ts">
  import { getContext } from 'svelte';
  const { data, xGet, yGet, x, yScale, xScale, y, height } = getContext('LayerCake');

  /** @type {String} [stroke='#000'] - The shape's stroke color. */
  export let stroke = '#000';

  /** @type {Number} [strokeWidth=0] - The shape's stroke width. */
  export let strokeWidth = 0;

  /** @type {Boolean} [false] - Show the numbers for each column */
  export let showLabels = false;

  export let highlightDivId: string;

  export let highlight: HighlightData | null;

  /** @type {function(any):number} */
  $: columnWidth = d => {
    const vals = $xGet(d);
    return Math.abs(vals[1] - vals[0]);
  };
  
/** @type {function(any):number} */
  $: columnHeight = d => {
    return $yScale(0) - $yGet(d);
  };

  const baselineStrokeWidth = 1;

  /* Code for determining which col is hlighted, if any */
  let someColIsFocused = false;
  function highlightThisCol(
    isMouseEvent: boolean, 
    potentialHighlightData: HighlightData
  ) {
    const isFocusEvent = !isMouseEvent;
    if (isFocusEvent) someColIsFocused = true;
    // Focus takes precedence: If we hover over a column but some column is
    // already focused, then do nothing.
    if (isMouseEvent && someColIsFocused) return;
    highlight = potentialHighlightData;
  }
  function unhighlightThisCol(isMouseEvent: boolean) {
    const isFocusEvent = !isMouseEvent;
    if (isFocusEvent) someColIsFocused = false;
    // Focus takes prececedence: If we unhover from a column but some column is
    // still focused, then do nothing
    if (isMouseEvent && someColIsFocused) return;
    highlight = null;
  }
</script>

<g
    class="column-group"
    class:no-col-is-focused={!someColIsFocused}
>
  {#each $data as d, i}
    {@const colHeight = columnHeight(d)}
    {#if colHeight}
      {@const xGot = $xGet(d)}
      {@const xPos = Array.isArray(xGot) ? xGot[0] : xGot}
      {@const colWidth = $xScale.bandwidth ? $xScale.bandwidth() : columnWidth(d)}
      {@const yValue = $y(d)}
      {@const year = $x(d)}

      <!-- svelte-ignore a11y-no-noninteractive-tabindex
        After a lot of research, there doesn't seem to be a good interactive
        aria role to assign to the rect (without being *more* confusing to
        screenreaders). Yet we still want it to be focusable so a user can
        tab and see the number for that year; therefore overriding. -->
      <rect
        class='group-rect'
        data-id="{i}"
        data-range="{year}"
        data-count="{yValue}"
        x="{xPos}"
        y="{colHeight < 0 ? $yScale(0) + baselineStrokeWidth : $yGet(d)}" 
        width="{colWidth}"
        height="{Math.abs(colHeight)}"
        {stroke}
        stroke-width="{strokeWidth}"
        tabindex="0"
        aria-controls={highlightDivId}
        aria-labelledby={highlight?.year === year ? highlightDivId : null}
        on:mouseenter={() => { highlightThisCol(true, {year, yValue}) }}
        on:focus={() => {highlightThisCol(false, {year, yValue}) }}
        on:mouseleave={() => { unhighlightThisCol(true) }}
        on:blur={() => { unhighlightThisCol(false) }}
      />
        
      {#if showLabels && yValue}
        <text x="{xPos + colWidth / 2}" y="{$height - colHeight - 5}" text-anchor="middle">{yValue}</text>
      {/if}
    {/if}
  {/each}
</g>

<style lang="scss">
  rect {
    fill: var(--color-accent-30);
    transition: fill 0.2s linear;
    &:focus, .no-col-is-focused &:hover {
      fill: var(--color-accent-70);
    }
  }

  text {
    fill: var(--gray-80);
    font-size: var(--scale-2);
  }
</style>
