<script lang="ts">
    // The propertiesInfo key is the property's index in the array of data
    type propertyInfo = {[key: string]: { label: string; }};
    const propertiesInfo:propertyInfo = {
        1: {label: "Revenue"},
        2: {label: "Expenses"},
        3: {label: "Total Assets"},
        4: {label: "Total Liabilities"},
    };
</script>

<figure class="org-top__right">
    <h3 class="a11y">Summary charts: organization finances over time</h3>

    <!-- Charts -->
    <div class="org-summary-charts-wrapper stack stack--row stack--spacing-none stack--wrap">
        {#each Object.keys(propertiesInfo) as yIndex}
            <div class="org-summary-chart">
                <h4>
                    <div class="property-label">{propertiesInfo[yIndex].label}</div>  
                    <div class="curr-value" aria-live="polite">--</div>
                </h4>
                <div class="chart-container">
                    <div>No data available</div>
                </div>
            </div>
        {/each}
    </div>
</figure>

<style lang="scss">
  @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";

  .org-summary-charts-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: var(--spacing0);
    
    @include breakpoint-max(sm) {
      grid-gap: var(--spacing-2);
    }

    .org-summary-chart {
        border: var(--gray-10) 1px solid;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.3);
    }
  }

  figure {
    margin-bottom: var(--spacing2);
    background: #F9FAFA;
  }

  // Modified from OrgSummaryChart.svelte
  h4 {
    border-bottom: 1px solid var(--gray-10);
    padding: var(--spacing0);

    @include breakpoint-max(sm) {
      padding: var(--spacing-1);
    }
    
    .property-label {
      font-size: var(--scale-1);
      font-weight: 500;
      color: var(--text-gray-mid);
    }
    .curr-value {
      font-size: var(--scale1);
      font-weight: 300;
      color: var(--gray-30);
      margin-top: var(--spacing-2); // Spacing between propety label and curr-value
    }
  }

  .chart-container {
    padding: var(--spacing0);
    margin-top: var(--spacing-2);
    width: 100%;
    height: 160px;

    @include breakpoint-max(lg) {
      height: 120px;
    }

    @include breakpoint-max(sm) {
      height: 80px;
      padding: var(--spacing-1);
    }

    color: var(--gray-30);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-size: var(--scale-2);
  }

</style>
