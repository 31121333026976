<script lang="ts">
    import { createEventDispatcher } from "svelte";

    import { Button } from "@propublica/pp-svelte";
    import CloseIcon from "@propublica/pp-v5-shared/src/icons/close.svg";
    import VerifyEmail, { EmailFormState } from "./VerifyEmail.svelte";
    import {fade} from 'svelte/transition';

    /* Props */

    export let ein: number;
    export let orgName: string;
    export let verifyEndpoint: string;

    // Props intended for two-way binding
    export let modalIsVisible: boolean;
    export let formState: EmailFormState;

    /* Config / constants */
    const headingText: Map<EmailFormState, string> = new Map([
        [EmailFormState.Fresh, "Subscribe to email alerts"],
        [EmailFormState.Sent, "Check your inbox"],
        [EmailFormState.RateLimit, "Try again later"],
    ]);

    /* Component state */
    const dispatch = createEventDispatcher<{ modalclose: null }>();
    let modalElem: HTMLDivElement;

    /* Event handlers */

    function closeModal(): void {
        modalIsVisible = false;
        dispatch("modalclose");
    }
    function handleKey(ev: KeyboardEvent): void {
        if (!modalIsVisible) return;

        if (ev.key === "Escape") closeModal();

        // Variation of:
        // - https://web.dev/articles/using-tabindex#modals_and_keyboard_traps
        // - https://github.com/udacity/ud891/blob/gh-pages/lesson2-focus/07-modals-and-keyboard-traps/solution/modal.js
        if (ev.key === "Tab") {
            // Redo this query on every tab press because the modal contents
            // change + because it doesn't seem that expensive performance-wise
            const focusableElems = modalElem.querySelectorAll(
                "a[href], input:not([disabled]),  button:not([disabled])"
            ) as NodeListOf<HTMLElement>;
            const firstFocusable = focusableElems[0];
            const lastFocusable = focusableElems[focusableElems.length - 1];

            // 3 cases where we want to override regular tab behavior
            // (otherwise let browser behave normally)

            // 1. Focus has "escaped" our modal (this can happen after
            // submitting the form but when the modal is still open)
            if (!modalElem.contains(document.activeElement)) {
                ev.preventDefault();
                firstFocusable.focus();
                return;
            }

            // 2. Shift-tabbing from first focusable: cycle to last focusable
            if (ev.shiftKey && document.activeElement === firstFocusable) {
                ev.preventDefault();
                lastFocusable.focus();
            }

            // 3. Tabbing from lsat focusable: cycle to first focusable
            if (!ev.shiftKey && document.activeElement === lastFocusable) {
                ev.preventDefault();
                firstFocusable.focus();
            }
        }
    }
</script>

<!-- Note this event listener is active even when modalIsVisible ===  false -->
<svelte:window on:keydown={handleKey} />

<!-- Due to https://github.com/propublica/pp-svelte/issues/80, this component
    (with top-level conditional) cannot be used directly in Rails. It must be a
    child of another Svelte component. -->
{#if modalIsVisible}
    <!-- svelte-ignore a11y-click-events-have-key-events
        Keydown handler on svelte:window serves this purpose. -->
    <div
        class="backdrop stack stack--justify-center stack--align-center"
        on:click|self={closeModal}
    >
        <div class="modal" bind:this={modalElem} transition:fade>
            <div class="modal-content-wrapper">
                <div class="modal-close-button">
                    <Button
                        iconOnly={true}
                        textColor="var(--color-text-meta)"
                        bgColor="transparent"
                        iconSize="var(--scale3)"
                        buttonSize="var(--scale2)"
                        horizontalSpacing="var(--spacing-2)"
                        on:click={closeModal}
                    >
                        Close email alerts signup form.
                        <CloseIcon slot="iconBefore" />
                    </Button>
                </div>
                <h2>{headingText.get(formState)}</h2>
                <VerifyEmail
                    endpoint={verifyEndpoint}
                    orgDetails={{ ein, orgName }}
                    bind:formState
                />
            </div>
        </div>
    </div>
{/if}

<style lang="scss">
    @import "@propublica/pp-v5-shared/src/css/column-setter-and-deps";
    .backdrop {
        z-index: 1000; // above subnav
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.85); // this is the same as the newsletter-roadblock
    }
    .modal {
        width: 36em;
        @include breakpoint-max(sm) {
            width: calc(100% - var(--spacing1) * 2);
        }

        border: 1px solid var(--gray-30);
        background-color: var(--color-light-full);
        padding: var(--spacing1);
        border-radius: .5em; // this is the same as the newsletter-roadblock
        position: relative;
        text-align: center;
    }

    .modal-content-wrapper {
        max-width: 26em;
        margin: 0 auto;
    }
    .modal-close-button {
        position: absolute;
        top: var(--spacing-1);
        right: var(--spacing-2);
    }
    h2 {
        margin-top: var(--spacing0);
        margin-bottom: var(--spacing-1);
        font-weight: 700;
        flex: 1 0 auto;
    }
</style>
