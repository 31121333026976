<script context="module" lang="ts">
    export enum EmailFormState {
        Fresh, // User can enter email address
        Sent, // Verification email sent
        RateLimit, // User has been rate limited (hide input)
    }
</script>

<script lang="ts">
    import { onMount } from "svelte";
    import { slide } from "svelte/transition"

    import { Button } from "@propublica/pp-svelte";
    import ArrowRightIcon from "@propublica/pp-v5-shared/src/icons/arrow-right.svg";

    import { csrfTokenPromise, snackbar } from "./stores.js";

    /* Props */
    export let endpoint: string;

    // Expected to be specified in modal, omitted on auth page
    export let orgDetails: { ein: number; orgName: string } | null = null;

    // Expected to have two-way binding in modal (to pass data "up"), omitted on
    // auth page
    export let formState: EmailFormState = EmailFormState.Fresh;

    // Uses orgDetails, which is only set in modals, as a proxy. Value is "modal" or "subscription-auth"
    export let onPage = orgDetails ? "modal" : "subscription-auth";

    /* Config / constants */
    const ICON_SIZE = "var(--scale1)";

    /* Component state */
    let clickBeingHandled = false;
    let emailAddress = "";
    let erroringEmailAddress: string | null = null;
    let inputElem: HTMLInputElement;

    /* onMount */

    onMount(() => {
        // When mounted in modal, autofocus input
        if (orgDetails) inputElem.focus();
    });

    /* Event handlers */

    // Should match request body expected by the send_verify route
    type VerifyRequestBody = { email_address: string; ein?: number };

    async function handleSubmit(): Promise<void> {
        clickBeingHandled = true;
        const csrfToken = await $csrfTokenPromise;
        if (csrfToken === null) {
            $snackbar.show("Authentication error.");
            clickBeingHandled = false;
            return;
        }
        const reqBody: VerifyRequestBody = { email_address: emailAddress };
        if (orgDetails) reqBody.ein = orgDetails.ein;
        const res = await fetch(endpoint, {
            method: "POST",
            headers: {
                // what we want response to be
                Accept: "application/json",
                // description of request body
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify(reqBody),
        });
        if (res.ok) {
            formState = EmailFormState.Sent;
        } else {
            erroringEmailAddress = emailAddress;
            // For non-rate limit error, formState remains "fresh" (allowing
            // user to try again immediately)
            if (res.status === 429) formState = EmailFormState.RateLimit;
        }
        clickBeingHandled = false;
    }
</script>

<p>
    {#if formState === EmailFormState.Sent}
        We sent an email to <strong>{emailAddress}</strong>. Click the link in
        that email to
        {#if orgDetails}
            finish setting up
        {:else}
            manage
        {/if}
        alerts.
    {:else if formState === EmailFormState.RateLimit}
        You have tried to send too many verification emails. Please wait an hour
        and try again.
    {:else if erroringEmailAddress !== null}
        There was an error sending an email to <strong
            >{erroringEmailAddress}</strong
        >. Please try again later or use a different email address.
    {:else if orgDetails}
        We’ll send you an email when we post new tax filings for
        <strong>{orgDetails.orgName}</strong>, which
        usually happens once a year.
    {:else}
        To manage your alerts, enter your email address.
    {/if}
</p>
<!-- When this component is top-level,
    https://github.com/propublica/pp-svelte/issues/80 means the following block
    cannot be re-rendered after it disappears, but it's fine because
    formState cn never become Fresh again -->
{#if formState === EmailFormState.Fresh}
    <form class="form" on:submit|preventDefault={handleSubmit} class:on-page-modal={onPage == "modal"} transition:slide>
        <label for="email__address" class="form__label" class:a11y={onPage == "modal"}>Email address</label>
        <div class="stack stack--align-center stack--spacing-1" class:stack--wrap={onPage == "modal"}>
            <!-- TODO: Refactor so client email maxlength pulls from same var as
            server -->
            <input
                class="form__input-text"
                type="email"
                id="email__address"
                placeholder="you@example.com"
                required
                maxlength="200"
                bind:value={emailAddress}
                bind:this={inputElem}
            />
            <!-- Default click behavior of button in form is to submit form-->
            {#if onPage == "modal"}
                <Button
                    bgColor="var(--color-accent-70)"
                    shape="pill"
                    buttonSize="var(--scale5)"
                >
                    {#if clickBeingHandled}
                        <div class="spinner">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="lucide lucide-loader-2"
                                ><path d="M21 12a9 9 0 1 1-6.219-8.56" />
                            </svg>
                        </div>
                    {:else}
                        Continue
                    {/if}
                </Button>
            {:else} 
                <Button
                    bgColor="var(--color-accent-70)"
                    shape="circle"
                >
                    {#if clickBeingHandled}
                        <div class="spinner">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="lucide lucide-loader-2"
                                ><path d="M21 12a9 9 0 1 1-6.219-8.56" />
                            </svg>
                        </div>
                    {:else}
                        <span class="icon-before">
                            <ArrowRightIcon slot="iconBefore" />
                        </span>
                        <span class="a11y">Submit</span>
                    {/if}
                </Button>
            {/if}
        </div>
    </form>
{/if}

<style lang="scss">
    form {
        margin-top: var(--spacing0);
        max-width: 30em;

        &.on-page-modal {
            margin: var(--spacing0) auto 0 auto;

            .form__label {
                padding: 0.8em;
                text-align: center;
            }
            input {
                text-align: center;
            }
        }
    }

    .form__label {
        margin-bottom: var(--spacing-2);
        font-size: var(--scale-1); //override default .form__label style
        font-weight: 500; //override default .form__label style
    }

    input {
        border: 1px solid var(--gray-20);
        border-radius: 3px;
        padding: 0.8em;

        &::placeholder {
            color: var(--gray-50);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .spinner {
        width: var(--scale2);
        height: var(--scale2);
    }
    .lucide-loader-2 {
        animation: spin 1s linear infinite;
        height: 24px;
        width: 24px;
    }

    .icon-before :global(svg) {
        width: var(--scale1);
    }
</style>
